import React, { Suspense } from 'react';
import 'reset-css';
import { Global } from '@emotion/core';
import styled from '@emotion/styled';

import fuzzyLogo from './img/romy-hero-blurred.png';
import globalStyles from './global-styles';
import Details from './Details';
import LeftPanel from './LeftPanel';

let TitleImage = styled.img`
  width: 640px;
  border-radius: 10px;
  box-shadow: 0px 0px 4rem white;
`;

function Img({ src, alt, ...props }) {
  return <TitleImage src={src} alt={alt} {...props} />;
}

const Page = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h1`
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
`;

const Content = styled.p`
  font-size: 1rem;
  margin-top: 1.5rem;
`;

const MainBody = styled.div`
  display: flex;
  flex-direction: row;
`;

const Center = styled.div`
  text-align: center;
`;

const Mood = styled.p`
  font-style: italic;
`;

const happyMoods = ['mlem', 'bork', 'snurf', 'yis', 'yiiiiiiissssssss'];

function App() {
  const [left, setLeft] = React.useState();
  const [right, setRight] = React.useState();
  const [happiness, setHappy] = React.useState(0);

  React.useEffect(() => {
    let id = setTimeout(() => {
      setHappy(Math.max(0, happiness - 1));
    }, 1000);

    return () => clearTimeout(id);
  }, [happiness]);

  return (
    <Page>
      <Global styles={globalStyles} />
      <Title>Romy's Homey</Title>
      <Suspense
        maxDuration={0}
        fallback={
          <TitleImage
            src={fuzzyLogo}
            alt="heroic pose of Romy looking at the ocean from a cliff"
          />
        }
      >
        <Img src="https://i.imgur.com/xHb72yE.png" />
      </Suspense>
      <Content>I am Romy. I am doge.</Content>

      <MainBody>
        <LeftPanel target={left} />
        <Center>
          <Details lefter={setLeft} righter={setRight} />
          <Center style={{ marginTop: '2rem' }}>
            <p style={{ marginBottom: '1rem' }}>Wat doin?</p>
            <button
              style={{ marginBottom: '1rem' }}
              onClick={() => setHappy(happiness + 1)}
            >
              Goo boi!
            </button>

            <Mood>
              {happyMoods[Math.floor(Math.random() * happyMoods.length)]}
            </Mood>
          </Center>
        </Center>
        <LeftPanel target={right} />
      </MainBody>
    </Page>
  );
}

export default App;
