import { css } from '@emotion/core';

const BLACK = '#111';

export default css`
  html {
    background: ${BLACK};
    color: white;
    font-family: sans-serif;
  }
`;
