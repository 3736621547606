import React from 'react';
import kittah from './img/mean-kittah.png';
import bestHooman from './img/best-hooman.png';
import otherHooman from './img/other-hooman.png';
import walkies from './img/walkies.png';
import nommin from './img/nommin.png';
import spaDae from './img/spa-dae.png';
import birbs from './img/birbs.png';
import otherDoggos from './img/other-doggos.png';
import styled from '@emotion/styled';

const imageMap = {
  'mean-kittah': kittah,
  'best-hooman': bestHooman,
  'other-hooman': otherHooman,
  walkies: walkies,
  'spa-dae': spaDae,
  nommin: nommin,
  birbs: birbs,
  'other-doggos': otherDoggos,
};

const Img = styled.img`
  width: 100%;
`;

export const Panel = styled.article`
  position: relative;
  flex: 1;
`;

export default ({ target }) => {
  return (
    <Panel>
      <Img src={imageMap[target] || ''} alt="" />
    </Panel>
  );
};
