import React from 'react';
import styled from '@emotion/styled';
import { Link } from '@reach/router';

const Table = styled.table`
  width: 50vw;
  text-align: center;
  margin-top: 1rem;
`;

const THead = styled.thead`
  font-weight: bold;
  text-decoration: underline;

  & td {
    padding-bottom: 0.5rem;
  }
`;

const MyLink = styled.span`
  cursor: pointer;
  color: blue;
`;

export default ({ lefter, righter }) => {
  return (
    <Table>
      <THead>
        <tr>
          <td>meetin's</td>
          <td>doin's</td>
        </tr>
      </THead>
      <tbody>
        <tr>
          <td>
            <MyLink onClick={() => lefter('mean-kittah')}>mean kittah</MyLink>
          </td>
          <td>
            <MyLink onClick={() => righter('walkies')}>walkies</MyLink>
          </td>
        </tr>
        <tr>
          <td>
            <MyLink onClick={() => lefter('best-hooman')}>best hooman</MyLink>
          </td>
          <td>
            <MyLink onClick={() => righter('nommin')}>nommin'</MyLink>
          </td>
        </tr>
        <tr>
          <td>
            <MyLink onClick={() => lefter('other-hooman')}>other hooman</MyLink>
          </td>
          <td>
            <MyLink onClick={() => righter('spa-dae')}>spa dae</MyLink>
          </td>
        </tr>
        <tr>
          <td>
            <MyLink onClick={() => lefter('other-doggos')}>other doggos</MyLink>
          </td>
          <td>
            <MyLink onClick={() => righter('birbs')}>chasin' birbs</MyLink>
          </td>
        </tr>
      </tbody>
    </Table>
  );
};
